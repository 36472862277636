import { Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { PlatformService, CookieService } from 'ngx-unificator/services';
import { ReplaySubject } from 'rxjs';
import { LocalstorageService } from 'ngx-unificator/services';
import { CmsApiService } from './api/cms-api.service';
import {SsApiService} from './api/ss-api.service';

export const PTAG_STRING = 'ptag';
export const PARTNER_ID_STRING = 'id_id';
export const STAG_STRING = 'stag';

@Injectable({
  providedIn: 'root',
})
export class StagDetectorService {


  public onDetectStag$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private _cookie: CookieService,
    private _router: Router,
    private _serializer: UrlSerializer,
    private _platform: PlatformService,
    private _cmsApi: CmsApiService,
    private _ssApi: SsApiService,
    private _storage: LocalstorageService,
  ) {

  }

  /**
   * Detect query parameters
   * @private
   */
  public detectQueryParameters() {
    if (!this._platform.isBrowser) {
      return;
    }
    const params = this._serializer.parse(window.location.search).queryParams;
    const domain = location.hostname.split('.').slice(-2).join('.');

    setTimeout(() => {
      if (params.stag || params.test || params.nocache) {
        this._detectTestParameter(params);
        if (params.stag) {
          this._detectStag(params, domain);
        }
        if (params.nocache) {
          delete params.nocache;
        }
        if (params.affb_id) {
          this._cookie.set('affb_id', `${params.affb_id}`, 999, '/', domain);
          this._storage.set('affb_id', `${params.affb_id}`);
          delete params.affb_id;
        }
        const tree = this._router.parseUrl(window.location.pathname);
        tree.queryParams = params;
        this._router.navigateByUrl(tree).then();
      } else if (!params.stag && this._cookie.check(PARTNER_ID_STRING)) {
        this._cmsApi.customNoLangHeadersList.push({ key: 'ID-ID', val: this._cookie.get(PARTNER_ID_STRING) });
        this._cmsApi.customHeadersList.push({ key: 'ID-ID', val: this._cookie.get(PARTNER_ID_STRING) });
      }
    }, 100);
  }

  /**
   * Save stag to cookie if exists
   *
   * @private
   */
  private _detectStag(params: any, domain: string) {
    if (params.stag) {

      this._detectAnCutStag(params, domain);

      this._ssApi.infoLocales(
        params.affb_id ? {
          affb_id: params.affb_id,
          stag: params.stag
        } : {
          stag: params.stag
        }
      ).subscribe();

      delete params.stag;

    } else if (!params.stag && this._cookie.check(PTAG_STRING)) {
      this._cookie.set(PARTNER_ID_STRING, this._cookie.get(PTAG_STRING).split('_')[0], 999, '/', domain);
      this._cmsApi.customNoLangHeadersList.push({ key: PARTNER_ID_STRING, val: this._cookie.get(PARTNER_ID_STRING) });
      this._cmsApi.customHeadersList.push({ key: PARTNER_ID_STRING, val: this._cookie.get(PARTNER_ID_STRING) });
    }
  }

  /**
   * Detect test parameter
   * @private
   */
  private _detectTestParameter(params) {
    if (params.test) this._storage.set('test', 'true');
    if (params.stag) {
      this._storage.set('stag', params.stag);
      this._storage.set(PARTNER_ID_STRING, params?.stag?.split('_')[0]);
    }

    delete params.test;
  }

  /**
   * Detect ptag and set and cut partner id stag for Back-End
   * @param params
   * @param domain
   * @private
   */
  private _detectAnCutStag(params, domain) {
    switch (true) {
      case this._cookie.check(PTAG_STRING) && this._cookie.check(PARTNER_ID_STRING):
        break;
      case this._cookie.check(PTAG_STRING):
        this._cookie.set(PARTNER_ID_STRING, this._cookie.get(PTAG_STRING).split('_')[0], 999, '/', domain);
        this._cmsApi.customNoLangHeadersList.push({ key: 'ID-ID', val: params.stag.split('_')[0] });
        this._cmsApi.customHeadersList.push({ key: 'ID-ID', val: params.stag.split('_')[0] });
        break;
      default:
        this._cookie.set(PARTNER_ID_STRING, params.stag.split('_')[0], 999, '/', domain);
        this._cookie.set(PTAG_STRING, params.stag, 999, '/', domain);
        this._storage.set(PARTNER_ID_STRING, params.stag.split('_')[0]);
        this._storage.set(STAG_STRING, params.stag);
        this._cmsApi.customNoLangHeadersList.push({ key: 'ID-ID', val: params.stag.split('_')[0] });
        this._cmsApi.customHeadersList.push({ key: 'ID-ID', val: params.stag.split('_')[0] });
    }
  }
}
