import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DataPreloaderService } from './core/services/data-preloader.service';
import { MainPreloaderComponent } from './core/shared/components/main-preloader/main-preloader.component';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, MainPreloaderComponent],
})
export class AppComponent {

  constructor(
    public loader: DataPreloaderService,
  ) {}

  title = 'spinpanda';
}
