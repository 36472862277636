import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PlatformService, CookieService } from 'ngx-unificator/services';
import { EnvironmentService } from './environment.service';
import { DOCUMENT } from '@angular/common';
import { WindowService } from './window.service';
import { cssLineralGradientToJSON, generateRegExp } from '../helpers/color';

/**
 * Name of cookie where current theme value is stored
 * use 'theme-dark' name instead 'theme' to support users previous cookie value (when we had only 2 themes)
 */
export const THEME_COOKIE = 'theme';

/**
 * Locales with only one theme - blue
 */
export const UK_THEME_LOCALES = 'uk';
export const HOLLAND_THEME_LOCALE = 'nl';

/**
 * Available themes
 */
export enum THEME {
  DEFAULT_DARK = 0,
  // HOLLAND = 1,
  // UK = 2,
}


/**
 * Global themes classes strings
 */
export const GLOBAL_CLASSES = {
  DEFAULT_DARK: 'default-dark',
  // HOLLAND: 'holland',
  // UK: 'uk',
};

export interface StopColor {
  color?: string;
  position?: string;
}

export interface ConvertedLinerGradient {
  original?: string;
  colorStopList?: StopColor[];
  line?: string;
  angle?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  /**
   * BehaviorSubject which hold current theme value
   */
  private _changeTheme$: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**
   * Current theme
   *
   * @private
   */
  private _currentTheme: THEME;

  /**
   * Access to _changeTheme$
   */
  public changeTheme$: Observable<any> = this._changeTheme$.asObservable();

  private _convertedLinerGradient: ConvertedLinerGradient = null;
  private _regExpLib = generateRegExp();

  constructor(
    private _cookie: CookieService,
    private _env: EnvironmentService,
    private _platform: PlatformService,
    private _window: WindowService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    forkJoin([this._resolveDefaultTheme$()]).pipe(
      tap(([isDefaultTheme]) => {
        this.setTheme(THEME.DEFAULT_DARK);
      })
    ).subscribe();
  }

  /**
   * Access to current theme value
   */
  get currentTheme(): THEME {
    return this._currentTheme;
  }

  /**
   * Access to available themes
   */
  get themes() {
    return THEME;
  }

  public get convertedLinerGradient(): ConvertedLinerGradient {
    return this._convertedLinerGradient;
  }

  /**
   * Use provided theme
   * @param theme
   * @param background
   */
  public setTheme(theme: THEME, background?: any) {
    if (theme !== this.currentTheme) {
      this._changeTheme$.next(theme);
      this._currentTheme = theme;
    }
  }

  /**
   * Resolve user default theme
   */
  private _resolveDefaultTheme$(): Observable<THEME> {
    return this._env.env$.pipe(
      map(() => {
        let userTheme;
        if (this._cookie.check(THEME_COOKIE)) {
          userTheme = parseInt(this._cookie.get(THEME_COOKIE), 10);
        } else {
          userTheme = THEME.DEFAULT_DARK;
          // switch (true) {
          //   case HOLLAND_THEME_LOCALE.includes(this._env.env.country.short):
          //     userTheme = THEME.HOLLAND;
          //     break;
          //   case DE_CA_THEME_LOCALE.includes(this._env.env.country.short):
          //     userTheme = THEME.DE_CA;
          //     break;
          // }
        }
        return userTheme;
      })
    );
  }

  /**
   * Current theme resolver
   */
  public resolveTheme$() {
    this.changeTheme$.pipe(
      tap((theme) => {
        this._document.documentElement.classList.add('theme-transition');
        switch (Number(theme)) {
          case this.themes.DEFAULT_DARK:
            this._addGlobalClass(GLOBAL_CLASSES.DEFAULT_DARK);
            break;
          // case this.themes.HOLLAND:
          //   this._addGlobalClass(GLOBAL_CLASSES.HOLLAND);
          //   break;
          // case this.themes.AU:
          //   this._addGlobalClass(GLOBAL_CLASSES.AU);
          //   break;
        }
        setTimeout(() => {
          this._document.documentElement.classList.remove('theme-transition');
          const cssStyle = this._window.nativeWindow.getComputedStyle(this._document.documentElement).getPropertyValue('--color-gradient');
          this._convertedLinerGradient = cssLineralGradientToJSON(this._regExpLib, cssStyle);
        }, 1500);
        if (this._platform.isBrowser) {
          this._cookie.set(THEME_COOKIE, theme.toString(), 999, '/', (window as any).location.hostname);
        }
      })
    ).subscribe();
  }

  /**
   * Add global class and remove previous classes
   * @param globalClass
   * @private
   */
  private _addGlobalClass(globalClass: string) {
    this._document.documentElement.classList.add(globalClass);
    this._filterGlobalClasses(globalClass);
  }

  /**
   * Remove and filter previous classes
   * @param key
   * @private
   */
  private _filterGlobalClasses(key: string) {
    Object.values(GLOBAL_CLASSES).forEach(item => {
      if (key !== item) {
        this._document.documentElement.classList.remove(item);
      }
    });
  }

}
