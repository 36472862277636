import { APP_INITIALIZER } from '@angular/core';
import { of, Observable } from 'rxjs';

/**
 * The function `createInitializerQueueList` creates a list of providers for Angular application
 * initializers based on an array of queue items.
 * @param {QueueItem[]} queueItems - An array of objects representing items in a queue. Each object
 * should have the following properties:
 * @returns The `createInitializerQueueList` function returns an array of provider objects that can be
 * used to initialize a queue of items in an Angular application. Each provider object specifies the
 * `APP_INITIALIZER` token, a factory function that executes the initializer function of a queue item,
 * dependencies for the factory function, and the `multi` flag set to `true` to indicate that multiple
 * providers can be registered for the
 */
function createInitializerQueueList(queueItems) {
  const providers = queueItems.map(item => ({
    provide: APP_INITIALIZER,
    useFactory: service => {
      const result = item.initializerFn(service);
      let promise = typeof result === 'function' ? of(result) : result;
      promise = promise instanceof Observable ? promise.toPromise() : promise;
      return () => promise;
    },
    deps: [item.token],
    multi: true
  }));
  return providers;
}

/**
 * Generated bundle index. Do not edit.
 */

export { createInitializerQueueList };
